<template>
  <div class="dealOrder">
    <div class="dealOrderTitle">
      <div class="myDealOrder">
        <p>我要提货</p>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input v-model="searchForm.commodityName" placeholder="商品分类"></el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            class="width-100"
            v-model="searchForm.startTime"
            type="datetime"
            placeholder="开始时间"
          ></el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            class="width-100"
            v-model="searchForm.endTime"
            type="datetime"
            placeholder="结束时间"
          ></el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="tableData" border>
      <el-table-column type="index" label="序号" width="70"></el-table-column>
      <el-table-column prop="name" label="商品"></el-table-column>
      <el-table-column prop="quantity" label="持仓数量"></el-table-column>
      <el-table-column prop="tradePrice" label="交易金额"></el-table-column>
      <el-table-column prop="warehouse" label="目的的仓库"></el-table-column>
      <el-table-column prop="status" label="状态"></el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="danger" size="small" @click="applyPickGoods(scope.row)">申请提货</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="pagination">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      tableData: [
        // {
        //   name: "钢材",
        //   quantity: 2,
        //   tradePrice: "300.00 RMB",
        //   warehouse: "天津仓库",
        //   holdStatus: "未提货"
        // }
      ],
      searchForm: {
        commodityName: null,
        startTime: null,
        endTime: null
      },
      current: 1,
      pageSize: 10,
      totalPage: null
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    resetSearch() {
      this.searchForm = {
        commodityName: null,
        startTime: null,
        endTime: null
      }
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryTakeGoodsOrderDetail.param.commodityName = this.searchForm.commodityName ? this.searchForm.commodityName : null;
      protocolFwd.param_queryTakeGoodsOrderDetail.param.startTime = this.searchForm.startTime ? this.searchForm.startTime : null;
      protocolFwd.param_queryTakeGoodsOrderDetail.param.endTime = this.searchForm.endTime ? this.searchForm.endTime : null;
      protocolFwd.param_queryTakeGoodsOrderDetail.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_queryTakeGoodsOrderDetail.param.page = this.current - 1;
      protocolFwd.param_queryTakeGoodsOrderDetail.param.size = this.pageSize;
      http
        .postFront(protocolFwd.param_queryTakeGoodsOrderDetail)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.tableData = value.content;
            this.totalPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    applyPickGoods(item) {
      this.$prompt("请输入提货数量", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /(^[1-9]\d*$)/,
        inputErrorMessage: "提货数量应为正整数"
      })
        .then(({ value }) => {
          protocolFwd.param_applyDeliveryTwo.param.commodityId =
            item.commodityId;
          protocolFwd.param_applyDeliveryTwo.param.holdId = item.holdId;
          protocolFwd.param_applyDeliveryTwo.param.quantity = value;
          http.postFront(protocolFwd.param_applyDeliveryTwo).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.$router.push("/matching/pickGoodsList");
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
      // 演示用
      // this.$prompt("请输入提货数量", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   inputPattern: /(^[1-9]\d*$)/,
      //   inputErrorMessage: "提货数量应为正整数"
      // })
      //   .then(({ value }) => {
      //     this.$EL_MESSAGE("成功");
      //     this.$router.push("/matching/pickGoodsList");
      //   })
      //   .catch(() => {
      //   });
    }
  }
};
</script>
<style scoped>
.dealOrder {
  padding: 0 15px;
}
.dealOrderTitle {
  overflow: hidden;
}
.dealOrder .searchList {
  margin-bottom: 10px;
}
.dealOrder .myDealOrder {
  float: left;
  line-height: 40px;
}
.dealOrderTitle .myDealOrder p {
  font-size: 16px;
}
.orderDetails {
  line-height: 35px;
}
.orderDetails .listTitle {
  display: block;
  text-align: right;
}
.orderDetails .listContent {
  margin-left: 5px;
}
.dealOrder .pagination {
  margin-top: 15px;
}
</style>
